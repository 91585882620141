import React from 'react';
import ArticleListItem from './articlelistitem';

class ArticleList extends React.Component {
  render() {
    // looks like this: /de/blog/
    // so we filter by the start of the item path, we automatically get
    // the language and the page type right
    let path = this.props.location.pathname.substring(0, 9);

    let items = this.props.items.filter(function(item) {
      return item.node.frontmatter.path.startsWith(path);
    });

    let nodes = items.map(item => {
      return (
        <ArticleListItem item={item} key={item.node.id}/>
      )
    })

    return (
      <ol>
        {nodes}
      </ol>
    )
  }
}
export default ArticleList;