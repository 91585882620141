import React from 'react';
import Link from '../net/link';
import i18n from '../i18n';
import moment from 'moment';
import 'moment/locale/de';
import { withTranslation } from 'react-i18next';

class ArticleListItem extends React.Component {
  render() {
    const { t } = this.props;
    let lang = i18n.language.substring(0,2);
    const link = this.props.item.node.frontmatter.path.substring(3); //the language will be added by link
    return (
      <li key={link}>
        <Link to={link}><h3>{this.props.item.node.frontmatter.title}</h3></Link>
        <div>{moment(this.props.item.node.frontmatter.date).locale(lang).format('LL')}</div>
        <p>{this.props.item.node.frontmatter.abstract}</p>
        <p><Link to={link}>{t('Knowledge.read_more')}</Link></p>
      </li>
    )
  }

} 
export default withTranslation()(ArticleListItem);